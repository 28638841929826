
<template>
    <div>
       <div class="state" >
         <div class="top">
           <img src="../../../assets/order/await.png" alt="">
           <span >获取结果大约3秒，请耐心等待...</span>
         </div>
        
       </div>
    </div>
</template>
<script>

export default {
    data(){
        return{

        }
    },
    mounted(){
            setTimeout(() => {
          window.parent.postMessage('*')
    }, 2000);
     
    },
}
</script>
<style lang="scss" scoped>
  .state{
      width: 330px;
      margin: 20% auto;
      .top{
          img{
            vertical-align: middle;
            margin-right: 10px;
           
          }
          span{
              font-size: 16px;
          }
      }
  }
</style>
